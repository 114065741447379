.page-template-diet {
    main {
        section {
            padding: 3.5rem 0 1.5rem 0;

            @include media-breakpoint-up(lg) {
                padding: 5rem 0;
            }

            @media print {
                padding: 2rem 0 0 0;
            }
        }

        .meal-navigation{
            font-family: 'Netto', sans-serif;
            position: relative;
            background: $white;
            padding: 0;
            z-index: 10;
            box-shadow: 0px 4px 6px rgba($black, 0.1);
            overflow-x: auto;

            &.active {
                transition: all 0.4s ease-in-out;
            }
            
            &.hidden {
                top: 0;
                transition: all 0.4s ease-in-out;
            }

            .container{
                padding: 0;
            }

            ul{
                white-space: nowrap;
                margin: 0;
                padding: 0.5rem 0;
                overflow-x: auto;

                @include media-breakpoint-up(sm) {
                    overflow: hidden;
                }

                .nav-item{
                    display: inline-block;
                }
                    
            }

            .nav-link{
                padding: 0.2rem 0.5rem;
                color: $grey;
                margin: 0 0.5rem;
                font-size: rem-calc(14);
                border-radius: $borderRadius;
                margin-bottom: -1px;
                white-space: nowrap;
                cursor: pointer;

                &:active, &:hover{
                    color: $grey;
                }

                &.active{
                    color: $white;
                    background: $primary;;
                }
            } 

            @media print {
                display: none;
            }
        }

        header {
            font-family: 'Netto', sans-serif;
            padding: 1rem 0 0 0;
            cursor: pointer;

            h1{
                color: $black;
                font-weight: 400;
                font-size: rem-calc(24);
                margin: 0 0 0.25rem 0;

                strong{
                    font-weight: 500;
                    font-size: rem-calc(30);
                }
            }

            p.BTW {
                color: $grey;
                margin-bottom: 0.75rem;
            }

            .diet-phase{
                display: inline-block;
                font-size: rem-calc(14);
                padding: 0.25rem 1rem;
                border-radius: $borderRadius;
                text-transform: uppercase;
                margin: 0 0 1rem 0;

                &.start, &.exit{
                    border-color: $orange-yellow;
                    color: $orange-yellow;
                }

                &.detox{
                    background: rgba($phaseDetoxColor, 0.15);
                    color: $phaseDetoxColor;
                }

                &.step{
                    background: rgba($phaseStepColor, 0.15);
                    color: $phaseStepColor;
                }

                &.balance{
                    background: rgba($phaseBalanceColor, 0.15);
                    color: $phaseBalanceColor;
                }

                &.fat_burning{
                    background: rgba($phaseFatBurningColor, 0.15);
                    color: $phaseFatBurningColor;   
                }

                &.stabilisation{
                    background: rgba($phaseStabilisationColor, 0.15);
                    color: $phaseStabilisationColor;   
                }
            }
        }

        .meal {
            padding: 0 0 10px 0;
            position: relative;

            @media print {
                //page-break-inside: avoid;
                border-bottom: none;
                padding: 0;
            }

            .white-box{
                @include media-breakpoint-up(lg) {
                    padding: 4rem;
                }
            }

            header{
                color: $white;
                border-radius: $borderRadius;
                padding: 1rem;
                box-shadow: 0px 4px 6px rgba($grey, 0.1);
                
                &.detox{
                    background: $phaseDetoxColor;
                    box-shadow: 0px 4px 6px rgba($phaseDetoxColor, 0.2);
                }

                &.step{
                    background: $phaseStepColor;
                    box-shadow: 0px 4px 6px rgba($phaseStepColor, 0.2);
                }

                &.balance{
                    background: $phaseBalanceColor;
                    box-shadow: 0px 4px 6px rgba($phaseBalanceColor, 0.2);
                }

                &.fat_burning{
                    background: $phaseFatBurningColor;
                    box-shadow: 0px 4px 6px rgba($phaseFatBurningColor, 0.2);   
                }

                &.stabilisation{
                    background: $phaseStabilisationColor;
                    box-shadow: 0px 4px 6px rgba($phaseStabilisationColor, 0.2);
                }

                @media print {
                    h2{
                        color: $black;
                    }
                    p.BTW{
                        color: $black;
                    }
                }
            }

            h2 {
                font-size: rem-calc(20);
                position: relative;
                font-weight: 400;
                color: $white;
                margin-bottom: rem-calc(10);

                strong{
                    font-weight: 500;
                }

                @media print {
                    margin: 0;
                }

                .collapse-arrow {
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: $white;
                    background: none;
                    border: none;
                    transition: all 0.3s ease-in-out;
                    padding: 0;

                    &:active, &:hover{
                        color: $white;
                        background: none;
                    }

                    &.open {
                        transform: rotate(180deg);
                    }
                }
            }

            h2.dishName {
                color: $black;
                font-weight: 500;
                font-size: rem-calc(24);
                text-align: center;
            }

            h3 {
                @media print {
                    margin-bottom: 0;
                }
            }

            p.BTW {
                font-size: rem-calc(16);
                color: $white;
                display: flex;
                justify-content: space-between;
                margin: 0;
                opacity: 0.75;
            }

            .info {
                display: block;
                font-size: 1.375rem;
                color: $grey;
                margin-bottom: 2rem;
                float: right;
            }

            .ingredients {
                font-size: 1rem;
                list-style: none;
                margin: 0 0 2rem 0;
                padding: 0;

                @include media-breakpoint-up(md) {
                    margin: 0 1rem 3rem 0;
                }

                li {
                    position: relative;
                    margin: 0.5rem 0;
                    // padding-left: 2rem;

                    @media print {
                        margin: 0;
                    }
                }

                .card-body {
                    .btn-change {
                        .img-sup {
                            vertical-align: super;
                        }
                    }
                }
            }

            .btn-change {
                vertical-align: middle;
                display: inline-block;
                margin: 0 0.5rem 0 0;
                width: 1.25rem;
                height: 1.25rem;
                padding: 0;
                background: none;
                border: none;
                outline: none;

                @media print {
                    display: none;
                }

                .img-fluid {
                    vertical-align: top;
                }
            }

            .card {
                position: relative;
                margin-top: 1rem;
                border-radius: 10px;
                border: 1px solid transparent;
                background: $primary;
                z-index: 0;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: $white;
                    z-index: -1;
                    border-radius: 9px;
                }

                .card-body {
                    padding: 1rem;
                    font-size: 1rem;
                    color: $primary;
                    text-align: center;

                    .ingredients {
                        text-align: left;
                        color: $font-color;
                        font-size: 1rem;
                        margin: 0;

                        li{
                            padding-left: 1.5rem;
                        }

                        button {
                            background: none;
                            border: none;
                            color: $font-color;
                        }
                    }
                }
            }

            .recipe {
                background: $light-grey;
                padding: 1rem;
                margin: 0 0 2rem 0;
                border-radius: 10px;

                h2{
                    font-size: rem-calc(18);
                    font-weight: bold;
                    color: $black;
                    text-align: center;
                }

                ol {
                    font-size: 1rem;
                    padding: 0 0 0 1.25rem;

                    li {
                        margin-bottom: 1rem;

                        @media print {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }

        .summary {
            font-family: 'Netto', sans-serif;
            padding: 0.5rem 0 1.5rem 0;
            font-size: 12px;

            h3 {
                font-size: 1.25rem;

                @include media-breakpoint-up(md) {
                    font-size: 1.75rem;
                }

                .big-number {
                    font-size: 2.75rem;
                    vertical-align: top;
                    line-height: 1;

                    @include media-breakpoint-up(md) {
                        font-size: 3.75rem;
                    }
                }
            }

            .btn-print {
                display: block;
                font-size: 1.375rem;
                color: $grey;
                float: right;
                text-decoration: underline;
            }

            
            .ingredients {
                // font-family: 'Titillium Web', sans-serif;
                font-size: 1.5rem;
                list-style: none;
                margin: 0 1rem 0 0;
                padding: 0;

                li {
                    position: relative;
                    margin: 1rem 0;
                }
            }

            .diet-phase{
                display: inline-block;
                font-size: rem-calc(14);
                padding: 0.25rem 1rem;
                border-radius: rem-calc(10);
                border: 1px solid;
                margin-bottom: 1rem;
        
                &.start, &.exit{
                    border-color: $orange-yellow;
                    color: $orange-yellow;
                }
        
                &.fast{
                    border-color: $purple;
                    color: $purple;
                }
        
                &.health{
                    border-color: $green;
                    color: $green;
                }
            }

            .not-active{
                color: $grey;

                .diet-phase{
                    &.fast{
                        border-color: $grey;
                        color: $grey;
                    }   
                }
            }

            .btn-change {
                vertical-align: middle;
                display: inline-block;
                margin: 0;
                width: 2.25rem;
                height: 2.25rem;
                padding: 0.375rem;

                @media print {
                    display: none;
                }

                .img-fluid {
                    vertical-align: top;
                }
            }

            .card {
                position: relative;
                margin-top: 1rem;
                border-radius: 0.3125rem;
                border: 1px solid transparent;
                background: $input-border;
                background-size: 200% auto;
                z-index: 0;

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: $light-grey;
                    z-index: -1;
                    border-radius: 0.25rem;
                }

                .card-body {
                    padding: 1rem;
                    font-size: 1rem;
                    text-align: left;
                    color: $primary;

                    .form-group .form-control {
                        background: $white;
                    }

                    .ingredients {
                        font-size: 1rem;
                        margin: 0;
                        color: $font-color;
                        text-align: left;
                    }

                    .list-of-dishes {
                        list-style-type: none;
                        display: inline;
                        margin: 0;
                        padding: 0;

                        li {
                            display: inline;
                        }
                    }
                }
            }
        }
        
        .makeDecision-icon{
            max-width: 100px;
            height: auto;
            margin: 0 auto 1rem auto;

            @include media-breakpoint-up(md) {

            }
        }
    }
}

//Animations

@keyframes rotateLeft {
    from {
        transform-origin: center;
        transform: translate3d(0, 0, 1, 0);
    }

    to {
        transform-origin: center;
        transform: rotate3d(0, 0, 1, -540deg);
    }
}

.rotateLeft {
    animation-name: rotateLeft;
}

@keyframes rotateRight {
    from {
        transform-origin: center;
        transform: rotate3d(0, 0, 1, -540deg);
    }

    to {
        transform-origin: center;
        transform: rotate3d(0, 0, 0);
    }
}

.rotateRight {
    animation-name: rotateRight;
}


.embed-responsive-3by2{
    &::before {
        padding-top: 66.67%;
    }
}

.border-right {
    border-right: 1px solid rgba($primary, 0.25);
}