.page-template-thnk-you {
    main{
        font-family: 'Netto', sans-serif;
        
        section{
            border-bottom: 1px solid $light-grey2;
        }
        
        .hero {
            padding: 0;
            background-image: url(../../assets/images/header.jpg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            border: none;
    
            h1 {
                margin: 4rem 0;
                text-align: center;
                text-transform: none;
                font-size: 30px;
                font-weight: 500;
    
                @include media-breakpoint-up(md) {
                    margin: 8rem 0;
                }
            }
        }

        ol {
            padding: 0 0 0 2.5rem;
            counter-reset: item;
            list-style: none;
    
            
    
            li {
                position: relative;
                font-size: 18px;
                font-weight: 700;
                color: $primary;
                margin-bottom: 2rem;
    
                &::before{
                    content: counter(item);
                    counter-increment: item;
                    position: absolute;
                    top: 0;
                    left: -2.5rem;
    
                    width: 2rem;
                    height: 2rem;
                    border-radius: 50%;
                    border: 1px solid #FFD1C5;
                    text-align: center;
                    line-height: 1.8rem;
                }
    
                p{
                    color: $black;
                    font-weight: 400;
                    margin-bottom: 1rem;
                }
            }
        }
    
        .flyer {
            font-family: 'Netto', sans-serif;
            position: relative;
            margin: 2rem auto;
            border-radius: $borderRadius;
            border: 1px solid $primary;
            
            header {
                padding: 1rem;
                background: $primary;
                color: $white;
                font-size: 1.25rem;
                font-weight: bold;
                border-radius: $borderRadius;
            }
    
            main {
                background: none;
                padding: 2rem 1rem 1rem 1rem;
            }
        }
    
        p.code {
            font-family: 'Netto', sans-serif;
            font-size: 2rem;
            text-align: center;
        }
    
        .store-link {
            display: inline-block;
            margin: 1rem 1rem 0 0;
        }
    }
}
