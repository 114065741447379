@import "~react-lazy-load-image-component/src/effects/blur.css";

.page-template-article-list {
    header {
        padding: 0 0 2rem 0;

        @include media-breakpoint-up(md) {
            padding: 2rem 0;
        }
    }

    main{
        background: $light-grey;
        display: flow-root;
    }

    .categoryFilter{
        font-family: 'Netto', sans-serif;
        background: $white;
        padding: 0;
        overflow-x: auto;
        border-bottom: 1px solid $light-grey2;

        .container{
            padding: 0;
        }

        ul{
            text-align: center;
            white-space: nowrap;
            margin: 0;
            padding: 0;
            overflow-x: auto;
            display: block;

            @include media-breakpoint-up(sm) {
                overflow: hidden;
            }

            .nav-item{
                display: inline-block;
            }
                
        }

        .nav-link{
            padding: 1rem;
            color: $grey;
            margin: 0;
            font-size: 1rem;
            white-space: nowrap;
            cursor: pointer;
            border-bottom: 2px solid $white;

            &:active, &:hover{
                color: $grey;
            }

            &.active{
                color: $primary;
                border-color: $primary;
                font-weight: bold;
            } 
        } 
    }

    .card-deck {
        margin-bottom: 0px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 30px;
        }
    }

    .card {
        position: relative;
        text-decoration: none;
        border-radius: $borderRadius;
        overflow: hidden;
        border: 1px solid $light-grey2;
        cursor: pointer;
        max-width: 100%;
        transition: all 0.3s ease-in-out;
        box-shadow: 0px 4px 6px rgba($black, 0.1);

        @include media-breakpoint-up(sm) {
            max-width: calc(33.3334% - 30px);
        }

        &.pinned{
            border: 5px solid $phaseBalanceColor;

            h2, p, span{
                color: $phaseBalanceColor !important;
            }
            
        }

        &.reveal{
            border: 5px solid;

            &.reveal-detox{
                border-color: $phaseDetoxColor;
            }

            &.reveal-balance{
                border-color: $phaseBalanceColor;
            }

            &.reveal-step{
                border-color: $phaseStepColor;
            }

            &.reveal-fat-burning{
                border-color: $phaseFatBurningColor;
            }
        }

        &.border-primary {
            border-width: 2px;
        }

        &.placeholder {
            height: 20rem;
            cursor: default;
        }

        &:after{
            content: '';
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 10rem;
            background-image: linear-gradient(
                to bottom,
                rgba($white, 0) 0%,
                rgba($white, 1) 100%,
            );
        }

        &:hover{
            .thumbImg {
                img{
                    transform: scale(1.2);
                }
            }
        }

        .thumbImg {
            position: relative;
            overflow: hidden;

            img {
                transform: scale(1);
                transition: .5s ease-in-out !important;
            }
        }

        .card-body {
            padding: 1rem 0;
            text-align: center;

            .category{
                color: $grey;
            }

            h2{
                margin: 0 1rem 1rem 1rem;
            }

            .phase-name{
                font-size: 1rem;
                text-transform: uppercase;
                letter-spacing: 0.2rem;
                margin: 0 0 1rem 0;

                &.detox{
                    color: $phaseDetoxColor;
                }

                &.balance{
                    color: $phaseBalanceColor;
                }

                &.step{
                    color: $phaseStepColor;
                }

                &.fat-burning{
                    color: $phaseFatBurningColor;
                }
            }

            p {
                color: $font-color;
                height: 10.5rem;
                overflow: hidden;
                margin: 0 1rem;
            }
        }
    }
}
