@import './elements/range';

.form-group{
    label{
        color: $font-color;
        font-size: 1rem;
        display: block;
        text-align: left;

        sup{
            color: $red
        }
    }
    .form-control{
        padding: 0;
        border-radius: $borderRadius;
        background: $input-bg;
        border: 1px solid $input-border;
        height: auto;

        &.is-invalid{
            border: 1px solid $primary;
        }

        &.select{
            // border: 2px solid $primary;
            // background: $white;

            .SVGInline {
                color: $primary;
            }
        }
    
        input{
            border: none;
            background: none;
            padding: 0.5rem 1rem;
            min-height: 40px;

            &:disabled{
                cursor: not-allowed;
            }

            &::-webkit-date-and-time-value{ 
                text-align:left;
            }
        }

        textarea{
            border: none;
            background: none;
            padding: 0.5rem 1rem;

            &:disabled{
                cursor: not-allowed;
            }
        }

        .input-group-text {
            background: none;
            border: none;
        }

        .input-placeholder{
            flex: 1 1 auto;
            padding: 0.5rem 1rem;
        }
    }

    .invalid-feedback{
        color: $primary;

        li{
            list-style: none;
        }
    }

    svg,
    .SVGInline {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        padding: 0.75rem;
        pointer-events: none;
        max-width: 3.5rem;
        z-index: 3;
    }

    &.is-invalid{
        .invalid-feedback{
            display: block;
        }
    }
}

select {
    padding: 0.5rem 1rem !important;
    border: none !important;
    border-radius: $borderRadius !important;
    background: transparent !important;
    //color: $primary !important;
    appearance: none;
    height: auto !important;
    cursor: pointer;

    &::-ms-expand {
        display: none;
    }
}

.form-check {
    .form-control{
        border: none;
        height: auto;

        &.is-invalid{
            background: none;
        }
    }
}

.invalid-feedback{
    color: $primary;

    li{
        list-style: none;
    }
}

.alert{
    border-radius: $borderRadius;
    // font-weight: bold;
    
    &.alert-info, &.alert-success{
        border: 1px solid $green;
        background-color: $green-opacity;
        color: $green;
    }

    &.alert-danger{
        border: 1px solid #F7E8D7;
        background-color: #FFF3E5;
        color: $primary;
    }

    &.alert-primary{
        border: 1px solid $primary;
        background: #FFF3E5;
        color: $font-color;
    }

    &.alert-secondary{
        border: 1px solid $primary;
        background: $white;
        color: $font-color;
    }

    h4{
        font-size: 1rem;
        font-style: italic;
        font-weight: bold;
    }

    ul{
        font-size: 1rem;
        list-style: disc;
        padding-left: 1.25rem;
        margin: 0;

        li{
            padding: 0;
            &:before{
                display: none;
            }
        }
        
    }
}

.custom-range {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 46px;
    outline: none;
    padding: 0;

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background: linear-gradient(
            to right,
            rgba($primary, 0.2) 0%,
            $primary var(--value),
            $light-grey2 var(--value),
            $light-grey2 100%
        );
        border-radius: 5px;
    }

    &::-moz-range-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background: linear-gradient(
            to right,
            rgba($primary, 0.2) 0%,
            $primary var(--value),
            $light-grey2 var(--value),
            $light-grey2 100%
        );
        border-radius: 5px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 30px;
        height: 30px;
        background: $white;
        border: 4px solid $orange;
        cursor: pointer;
        margin-top: -10px;
    }

    &::-moz-range-thumb {
        width: 22px;
        height: 22px;
        background: $white;
        border: 4px solid $orange;
        cursor: pointer;
        margin-top: -10px;
    }

    &::before {
        content: attr(min);
        position: absolute;
        left: 0;
        bottom: -0.5rem;
        font-size: rem-calc(14);
        color: $font-color;
    }

    &::after {
        content: attr(max);
        position: absolute;
        right: 0;
        bottom: -0.5rem;
        font-size: rem-calc(14);
        color: $font-color;
    }
}
