h1, .heading-1 {
    color: $black;
    font-size: rem-calc(36);
    margin-bottom: 1.5rem;
    font-family: 'Netto', sans-serif;
    letter-spacing: normal;
    text-transform: uppercase;
    font-weight: 700;
}

h2, .heading-2 {
    color: $black;
    font-size: rem-calc(24);
    margin-bottom: 1rem;
    font-family: 'Netto', sans-serif;
    letter-spacing: normal;

    @include media-breakpoint-up(sm) {
        font-size: rem-calc(24);
    }

    &.primary{
        color: $primary;
    }
}

h3, .heading-3 {
    font-family: 'Netto', sans-serif;
    color: $black;
    font-size: rem-calc(18);
    margin-bottom: 1rem;
    font-weight: 700;

    &.primary{
        color: $primary;
    }
}

h4, .heading-4{
    font-size: 1rem;
    color: $black;
    font-weight: bold;
    margin-bottom: 1rem;
    -webkit-font-smoothing: antialiased;
}

p {
    font-size: 1rem;

    &.black {
        color: $black;
    }

    &.bold {
        font-weight: 700;
    }
}

a {
    word-wrap: break-word;

    &.grey {
        color: $grey;
    }
}

ul.list {
    font-size: 1.5rem;
    padding: 0;
    list-style: none;

    li {
        position: relative;
        padding: 0 0 0 2rem;

        &:before {
            content: '';
            position: absolute;
            top: 0.5rem;
            left: 0;
            width: 1rem;
            height: 1rem;
            background-image: url(../assets/icons/check-green.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }
        
    }
}
