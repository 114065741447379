@import "~react-lazy-load-image-component/src/effects/blur.css";

.page-template-cms-article {
    main{
        header {
            padding: 2rem 0 0 0;
    
            @include media-breakpoint-up(md) {
                padding: 2rem 0;
            }
    
            h1 {
                text-align: center;
                font-size: 1.75rem;
                margin: 0;
    
                @include media-breakpoint-up(md) {
                    font-size: 2.25rem;
                }
            }
        }
    
    
        .article-img{
            overflow: hidden;
            
            @include media-breakpoint-up(md) {
                margin-bottom: 2rem;
            }
    
        }
    
        .embed-responsive{
            margin: 2rem 0;
        }

        ul {
            padding: 0 0 0 1rem;
            list-style: none;
    
            li {
                position: relative;
                font-size: 1rem;
                padding: 0 0 0 1rem;
                background-image: url(../../assets/icons/bullet.svg);
                background-repeat: no-repeat;
                background-position: top 9px left;
            }
        }
    
        ol {
            font-size: 1rem;
            padding: 0 0 0 1.25rem;
    
            li {
                padding: 0;
            }
        }
    
        .blockquote {
            position: relative;
            margin-left: 3.5rem;
            padding-left: 1rem;
            border-left: 2px solid $primary;
    
            &:before {
                content: '\201D';
                font-family: Arial, Helvetica, sans-serif;
                position: absolute;
                top: -2rem;
                left: -3.5rem;
                color: $primary;
                font-size: 6.25rem;
            }
        }
    
        p.sign {
            position: relative;
            text-align: right;
            margin: 1rem 0 1rem 0;
            color: $blue;
        }
    
        .article-lead,
        .article-summary {
            margin: 2rem auto;
            color: $black;
            font-weight: bold;
        }
    
        hr {
            //max-width: 700px;
            margin-left: auto;
            margin-right: auto;
        }
    
        strong{
            color: $black;
        }

        .author{
            border-bottom: 1px solid $light-grey2;
            padding: 0 0 0.5rem 0;

            img{
                margin: 0 0.5rem 0 0;
                vertical-align: bottom;
            }
        }
    
        .cms-content {
            margin-left: auto;
            margin-right: auto;
    
            h1 {
                text-align: center;
            }
    
            h2 {
                text-align: center;
            }
    
            h3 {
                margin: 0 0 1rem 0;
            }

            .true{
                text-align: center;
            }

            .paragraph-header{
                text-align: center;
            }
    
            .blockquote {
                position: relative;
                margin-left: 3.5rem;
                padding-left: 1rem;
                border-left: 2px solid $primary;
    
                &:before {
                    content: '\201D';
                    font-family: Arial, Helvetica, sans-serif;
                    position: absolute;
                    top: -2rem;
                    left: -3.5rem;
                    color: $primary;
                    font-size: 6.25rem;
                }
            }
    
            img {
                display: block;
                margin: 0 auto;
                max-width: 100%;
                height: auto;
            }
    
            .collapse-item {
                border-bottom: 1px solid $light-grey;
        
                &:last-child {
                    border-bottom: none;
                }
            }
        
            h3 {
                margin: 1rem 0;
                padding-right: 2rem;
                position: relative;
        
                button{
                    background: none;
                    border: none;
                    color: $black;
                    font-weight: 500;
                    text-align: left;
                    display: block;
                    width: 100%;
                    outline: none;
                }
        
                span.collapse-arrow {
                    display: flex;
                    align-items: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: $primary;
                    height: 100%;
                    transition: all 0.3s ease-in-out;
        
                    &.open {
                        transform: rotate(180deg);
                    }
                }
            }
        
            .card {
                border: none;
                margin-bottom: 1rem;
        
                .card-body {
                    background: $light-grey;
                    border-radius: $borderRadius;
                    padding: 1rem;
        
                    ul{
                        padding: 0 0 0  1.25rem;
                    }
                }
            }
    
            .pageActions {
                text-align: center;
    
                // a[role='button'] {
                //     @extend .btn;
                //     @extend .btn-blue;
                // }
            }
    
            aside {
                &.right {
                    float: right;
                    margin-left: 1rem;
                    margin-bottom: 1rem;
                }
    
                &.left {
                    float: left;
                    margin-right: 1rem;
                    margin-bottom: 1rem;
                }
    
                &.did-you-know{
                    position: relative;
                    border: 1px solid $light-grey2;
                    border-radius: $borderRadius;
                    padding: 2rem;
                    margin: 2rem 0;
                    
                    
                    &:before{
                        content: '';
                        position: relative;
                        display: block;
                        width: 6rem;
                        height: 6rem;
                        background-size: 6rem;
                        background-image: url(../../assets/icons/bulb.svg);
                        background-repeat: no-repeat;
                        margin: 0 auto 1rem auto;

                    }

                    @include media-breakpoint-up(md) {
                        display: flex;
                        align-items: center;

                        &:before{
                            flex: 0 0 auto;
                            margin: 0 2rem 0 0;
                        }
                    }
    
                    p{
                        font-size: 1.125rem;
                        margin: 0;

                        @include media-breakpoint-up(md) {
                            flex: 0 1 auto;
                        }
                    }
                }
            }
        }

        .author-info {
            .author-name {
                font-weight: bold;
            }
        }
        
    }
}
